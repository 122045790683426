<template lang="pug">
include ../../../configs/template
div
  div.d-flex.wrap.text-left.align-end.mx-3
    div.col-xs-12.col-sm-12.col-md-6.col-lg-4.col-xl-4
      +field-validation('body.registry_number', 'registrationNumber', '["required"]')
    div.col-xs-12.col-sm-12.col-md-6.col-lg-4.col-xl-4
      +field-validation('body.serial', 'serial', '["required"]')
    div.col-xs-12.col-sm-12.col-md-6.col-lg-4.col-xl-4
      +field-validation('body.number_document', 'number', '["required"]')
    div.col-xs-12.col-sm-12.col-md-6.col-lg-4.col-xl-4
      +date-picker-validation('body.date_start_educ', 'date_start_educ', 'dateStartEduc', '["required"]')(:max="body.date_end_educ" min="1900-01-01")
    div.col-xs-12.col-sm-12.col-md-6.col-lg-4.col-xl-4
      +date-picker-validation('body.date_end_educ', 'date_end_educ', 'dateEndEduc', '["required"]')(:min="body.date_start_educ" :max="new Date().toISOString()")

    div.col-xs-12.col-sm-12.col-md-6.col-lg-4.col-xl-4
      +field('body.special_notes', '"notes"')
    div.w-100.text-center.pb-6
      v-btn(:loading="buttonLoader" @click="checkInfo" color="success") {{ $t('save') }}
</template>

<script>
import { hideDetailed } from '@/mixins/main'
import { CreateDocumentWithStatement } from '@/mixins/validationRules'
import { mapActions, mapState } from 'vuex'
import { SUCCESS_CODE } from '../../../configs/constants'

const initBody = () => (
  {
    number_document: null,
    serial: null,
    registry_number: null,
    special_notes: null,
    date_start_educ: null,
    date_end_educ: null
  }
)

export default {
  name: 'SeafarerGraduationApplicationTransfer',
  props: {
    sailorDocument: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      body: initBody(),
      hideDetailed,
      buttonLoader: false

    }
  },
  computed: {
    ...mapState({
      id: state => state.sailor.sailorId
    }),
    dateStartEduc () {
      return this.body.date_start_educ ? new Date(this.body.date_start_educ) : null
    },
    dateEndEduc () {
      return this.body.date_end_educ ? new Date(this.body.date_end_educ) : null
    }
  },
  validations () { return CreateDocumentWithStatement(this) },
  methods: {
    ...mapActions(['createDocumentWithStatement']),
    /** Check fields validation before submit */
    checkInfo () {
      if (this.$v.$invalid) {
        this.$v.$touch()
      } else this.transferApplication()
    },

    /** Transfer application to document */
    async transferApplication () {
      this.buttonLoader = true
      const response = await this.createDocumentWithStatement({ ...this.$route.params, body: this.body })

      if (SUCCESS_CODE.includes(response.code)) {
        this.$notification.success('transferredApplication')
        this.$router.push({
          name: 'education-documents-info',
          params: { id: this.$route.params.id, documentID: response.data.document_id } })
      }
      this.buttonLoader = false
    }
  }
}

</script>
